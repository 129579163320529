// _base.scss
$font-stack: 'Open Sans', sans-serif;
$primary-color: #dc0909;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.App {
    font-family: $font-stack;
}
.max-w-786 {
    max-width: 786px;
}
.main-block {
    height: 100dvh;
    background-color: #f3f5f6;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    position: relative;
    display: flex;
    flex-direction: column;
}
