.navPosition {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.resetPosition {
    position: inherit;
}
.bg-nav-color {
    background-color: #898e91;
}
.nav a {
    color: #ffffff;
    padding: 11px;
    font-size: 13px;
}
.nav a:hover {
    color: #ffffff;
    background-color: #5e6366;
}

@media (max-width: 400px) {
    .nav a {
        padding: 6px 5px;
        font-size: 12px;
    }
}
