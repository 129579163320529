.expire-block {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    .site-logo {
        max-width: 72px;
    }
}
.expire-content {
    font-size: 16px;
    font-weight: 600;
    color: #5e6366;
    margin-bottom: 0;
    line-height: 24px;
}
.expire-content-block a {
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
}
.custom-width {
    width: 210px;
    margin: 0 auto;
}
